import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { useTranslation } from 'react-i18next';
import { useStyles } from './EditLabelModal.styles.js';
import CircularProgress from '@mui/material/CircularProgress';
import OutlinedInput from '@mui/material/OutlinedInput';
import { appActions } from '../../actions/app.actions.js';
import { useDispatch } from 'react-redux';
import { labelLength, tabPrefix } from '../../constants/app.constants.js';

export default function EditLabelModal(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [label, setLabel] = useState('');
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  const { open, loading, alignment } = props;
  const dispatch = useDispatch();

  useEffect(() => setLabel(props.label), [props.label]);

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const onClick = res => {
    if (!res) return props.onEditModalClose();

    const maxLength = labelLength[alignment];
    if (label.length < maxLength) {
      props.onEdit(label);
    } else {
      const messageKey = `${tabPrefix[alignment]}.modal.longLabel`;
      dispatch(appActions.openMessageModal(t(messageKey)));
    }
  };
  return (
    <Modal className={fontStyle} open={!!open} onClose={() => props.onEditModalClose()}>
      <div className={classes.container}>
        {!loading && (
          <>
            <div className={classes.label}>
              <OutlinedInput onChange={e => setLabel(e.target.value)} value={label} className={classes.labelInput} />
            </div>
            <div className={classes.buttonContainer}>
              <div className={classes.yesButton} onClick={() => onClick(true)}>
                {t('historyTab.changeButton')}
              </div>
              <div className={classes.noButton} onClick={() => onClick(false)}>
                {t('historyTab.cancelButton')}
              </div>
            </div>
          </>
        )}
        {loading && <CircularProgress className={classes.loading} />}
      </div>
    </Modal>
  );
}
