export const appConstants = {
  OPEN_LOGIN_MODAL: 'OPEN_LOGIN_MODAL',
  CLOSE_LOGIN_MODAL: 'CLOSE_LOGIN_MODAL',
  OPEN_MESSAGE_MODAL: 'OPEN_MESSAGE_MODAL',
  CLOSE_MESSAGE_MODAL: 'CLOSE_MESSAGE_MODAL',
  OPEN_MESSAGE_MODAL_V2: 'OPEN_MESSAGE_MODAL_V2',
  CLOSE_MESSAGE_MODAL_V2: 'CLOSE_MESSAGE_MODAL_V2',
  OPEN_DOWNLOAD_MODAL: 'OPEN_DOWNLOAD_MODAL',
  CLOSE_DOWNLOAD_MODAL: 'CLOSE_DOWNLOAD_MODAL',
  OPEN_LANGUAGE_MODAL: 'OPEN_LANGUAGE_MODAL',
  CLOSE_LANGUAGE_MODAL: 'CLOSE_LANGUAGE_MODAL',
  SET_INITIALIZED: 'SET_INITIALIZED',
  SELECT_COMMUNITY_ITEM: 'SELECT_COMMUNITY_ITEM',
  CLEAR_SELECTED_COMMUNITY_ITEM: 'CLEAR_SELECTED_COMMUNITY_ITEM',
  OPEN_CUSTOM_MESSAGE_MODAL: 'OPEN_CUSTOM_MESSAGE_MODAL',
  CLOSE_CUSTOM_MESSAGE_MODAL: 'CLOSE_CUSTOM_MESSAGE_MODAL',
};

export const SubscriptionType = {
  FREE: 'FREE',
  BASIC: 'BASIC',
  PRO: 'PRO',
  PREMIUM: 'PREMIUM',
};

export const Currency = {
  KRW: 'krw',
  USD: 'usd',
  THB: 'thb',
  JPY: 'jpy',
};

export const labelLength = {
  voice: 25,
  music: 100,
  vocalExtract: 50,
  speech: 50,
};

export const tabPrefix = {
  voice: 'trainingTab',
  music: 'inferenceTab',
  vocalExtract: 'vocalExtractorTab',
  speech: 'ttsInferenceTab',
};

//export const backendBaseUrl = 'http://localhost:80';
//export const backendBaseUrl = 'https://api.sorisori.ai';
export const backendBaseUrl = 'https://apibeta.sorisori.ai';

export const revideoUrl = 'https://sori-video-player.s3.us-east-2.amazonaws.com/';
//export const revideoUrl = 'http://app.sorisori.ai/player/';
//export const revideoUrl = 'http://beta.sorisori.ai/player/';
