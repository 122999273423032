import Modal from '@material-ui/core/Modal';
import { useStyles } from './TextEditorModal.styles';

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';

import { ChromePicker } from 'react-color';

export default function TextEditorModal(props) {
  const { t } = useTranslation();
  
  const { open, mode, textSource } = props;

  const [text, setText] = useState(textSource?.text || '');
  const [color, setColor] = useState(textSource?.color || '#fff');
  const [weight, setWeight] = useState(textSource?.weight || 400);

  const [anchorEl, setAnchorEl] = useState();
  const colorPickerOpen = Boolean(anchorEl);
  
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const handleAddText = () => {
    props.onAdd(text, color, weight);
    setText('');
    setColor('#fff');
    setWeight(400);
  }

  const handleEditText = () => {
    props.onEdit(text, color, weight);
    setText('');
    setColor('#fff');
    setWeight(400);
  }

  useEffect(() => {
    if (textSource) {
      setText(textSource.text || '');
      setColor(textSource.color || '#fff');
      setWeight(textSource.weight || 400);
    }
  }, [textSource]);

  const classes = useStyles();
  return (
    <>
      <Modal
        open={!!open}
        onClose={() => {
          props.onTextEditorModalClose();
          setText('');
          setColor('#fff');
          setWeight(400);
        }}
        className={`${classes.modal} ${fontStyle}`}
      >
        <div className={classes.container}>
          <div className={classes.modalTitle}>
            {mode === 'add' ? t('textEditorModal.title.0') : t('textEditorModal.title.1')}
          </div>
          <div className={classes.textFieldContainer}>
            <TextField
              rows={3}
              maxRows={3}
              multiline
              fullWidth
              size='small'
              value={text}
              className={classes.textField}
              onChange={e => setText(e.target.value)}
              placeholder={t('textEditorModal.placeholder')}
              InputProps={{
                inputProps: {
                  style: {
                    textDecoration: 'none',
                    fontWeight: weight,
                    color: color,
                  }
                }
              }}
            />
          </div>
          <div className={classes.buttonContainer}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                value={weight}
                className={classes.selectContainer}
                style={{ fontWeight: weight }}
                onChange={(e) => setWeight(e.target.value)}
              >
                <MenuItem value={300} style={{ fontWeight: 300 }} dense>
                  Aa
                </MenuItem>
                <MenuItem value={400} style={{ fontWeight: 400 }} dense>
                  Aa
                </MenuItem>
                <MenuItem value={500} style={{ fontWeight: 500 }} dense>
                  Aa
                </MenuItem>
              </Select>
              <Button
                className={classes.iconButton}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <div
                  className={classes.colorPreview}
                  style={{ backgroundColor: color }}
                />
              </Button>
              <Popover
                open={colorPickerOpen}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <ChromePicker
                  color={color}
                  onChange={(color) => setColor(color.hex)}
                  className={classes.colorPicker}
                />
              </Popover>
              </div>
            <div>
              {mode === 'add' && (
                <Button
                  className={classes.addButton}
                  onClick={() => handleAddText()}
                  disabled={text.length === 0}
                >
                  {t('textEditorModal.add')}
                </Button>
              )}
              {mode === 'edit' && (
                <Button
                  className={classes.addButton}
                  onClick={() => handleEditText()}
                  disabled={text.length === 0}
                >
                  {t('textEditorModal.save')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
