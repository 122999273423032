import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  body: {
    maxWidth: '100%',
    overflowX: 'hidden',
  },
  canvasContainer: {
    [theme.breakpoints.up('md')]: {
      marginTop: '-3.5rem!important',
    },
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0.5rem 0',
  },
  canvas: {
    backgroundColor: '#000',
    border: '1px solid #A4A4A480',
    borderRadius: '0.3rem',
    overflow: 'hidden',
    position: 'relative',
    cursor: 'pointer'
  },
  verticalCanvas: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '14.4rem!important',
    },
    aspectRatio: '9 / 16',
    maxWidth: '11.7rem!important',
  },
  horizontalCanvas: {
    [theme.breakpoints.up('md')]: {
      maxHeight: '25.6rem',
    },
    aspectRatio: '16 / 9',
    maxHeight: '22.4rem',
  },
  disableClick: {
      cursor: 'default',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 50,
  },
  exportButton: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    position: 'absolute',
    bottom: '0.5rem',
    right: '0.5rem',
    backgroundColor: '#CAFF73',
    color: '#000',
    padding: '0.2rem',
    fontSize: '1.1rem',
    minWidth: 0,
    zIndex: 99,
    '&:hover': {
      backgroundColor: '#CAFF73',
    },
    '&.Mui-disabled': {
      color: '#000',
      opacity: '0.5'
    }
  },
  verticalButton: {
    backgroundColor: '#161616',
    color: '#fff',
    padding: '0',
    margin: '0.5rem 0.3rem',
    transition: '150ms',
    '&:hover': {
      backgroundColor: '#161616',
    },
    '&.Mui-disabled': {
      opacity: '0.3',
      color: '#fff!important'
    }
  },
  horizontalButton: {
    backgroundColor: '#161616',
    color: '#fff',
    padding: '1.5rem 0',
    minWidth: '0',
    margin: '0.5rem',
    transition: '150ms',
    '&:hover': {
      backgroundColor: '#161616',
    },
    '&.Mui-disabled': {
      opacity: '0.3',
      color: '#fff!important'
    }
  },
  clipControlContainer: {
    display: 'flex',
    gap: '0.5rem',
  },
  scaleInputContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#161616',
    color: '#fff',
    borderRadius: '0.25rem',
    transition: '150ms',
  },
  disabledInput: {
    opacity: '0.3'
  },
  scaleButton: {
    minWidth: 0,
    color: '#fff!important',
  },
  scaleInput: {
    textAlign: 'center',
    fontSize: '0.75rem',
    width: '2.5rem',
    height: '2rem,'
  },
  controlButton: {
    backgroundColor: '#161616',
    color: '#fff',
    padding: '0.3rem',
    minWidth: '0',
    transition: '150ms',
    '&.Mui-disabled': {
      opacity: '0.3',
      color: '#fff!important'
    }
  },
  timelineOuterContainer: {
    color: '#fff',
    backgroundColor: '#0E0E0E',
    margin: '0 -2.5rem',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '20.7rem',
  },
  
  timelineBar: {
    alignItems: 'center'
  },
  timelineButton: {
    color: '#fff!important',
    minWidth: '0',
    "&.Mui-disabled": {
      color: '#818181!important'
    }
  },
  toggleButton: {
    minWidth: '0',
    padding: '0.3rem',
  },
  activeToggleButton: {
    backgroundColor: '#CAFF73!important',
    '&:hover': {
      backgroundColor: '#CAFF73!important',
    }
  },
  timelineControls: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timelineButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  iconButton: {
    width: '1.5rem',
  },
  addSourceButton: {
    [theme.breakpoints.down('xs')]: {
      padding: '0.3rem',
    },
    backgroundColor: '#fff',
    color: '#000',
    padding: '0.3rem 0.8rem',
    fontFamily: 'inherit',
    fontSize: '0.7rem',
    fontWeight: 400,
    textTransform: 'none',
    minWidth: '16px',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
  addTextButton: {
    backgroundColor: '#fff',
    color: '#000',
    padding: '0.3rem',
    minWidth: 0,
    '&:hover': {
      backgroundColor: '#fff',
      color: '#000',
    },
  },
  buttonIcon: {
    [theme.breakpoints.down('xs')]: {
      display: 'block!important',
    },
    display: 'none!important',
  },
  buttonText: {
    [theme.breakpoints.down('xs')]: {
      display: 'none!important'
    },
    display: 'block!important',
  },
  timestamp: {
    fontSize: '0.8rem',
    height: '100%',
    fontWeight: 400,
    marginRight: '0.5rem'
  },
  zoomInputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  zoomButton: {
    fontSize: '1rem',
    color: '#fff!important',
    minWidth: 0,
    transition: '150ms',
    "&.Mui-disabled": {
      color: '#818181!important'
    }
  },
  zoomInput: {
    fontSize: '0.8rem',
  },
  selectContainer: {
    backgroundColor: '#0E0E0E',
    color: '#fff',
    padding: '0.1rem 0.5rem',
    borderRadius: '0.25rem',
    marginRight: '0.5rem',
    '& .MuiSelect-icon': {
      color: '#fff!important',
    },
  },
  timelineEditor: {
    padding: '0 1rem 1rem 0',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    '& .timeline-editor': {
      height: '20.5rem',
      width: '100%',
      backgroundColor: '#0E0E0E',
    },
    '& .timeline-editor-edit-row': {
      backgroundImage: 'linear-gradient(#0E0E0E, #0E0E0E), linear-gradient(90deg, rgba(255, 255, 255, 0.2) 1px, transparent 0)',
    },
    '& div': {
      webkitUserSelect: 'none!important',
      userSelect: 'none!important',
    },
    '& .timeline-editor-action': {
      height: '2.4rem!important',
      borderRadius: '0.2rem',
      minWidth: '24px!important'
    },
    '& .timeline-editor-cursor': {
      borderColor: '#fff',
      '& svg': {
        '& path': {
          fill: '#fff',
        }
      },
    },
  },
  timelineList: {
    zIndex: '99',
    width: '5.5rem',
    height: '17.925rem',
    marginTop: '42px',
    boxShadow: '8px 0 30px -2px #0E0E0E',
    overflowY: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  timelineListItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
  },
  timelineClip: {
    display: 'flex',
    alignItems: 'center',
    height: '2.4rem !important',
    borderRadius: '0.2rem',
    backgroundColor: '#161616',
    border: '2px solid var(--clip-color)',
    borderRight: '.5rem solid var(--clip-color)',
    borderLeft: '.5rem solid var(--clip-color)',
    padding: '0 0.5rem',
  },
  activeClip: {
    backgroundColor: 'var(--clip-color)',
    backgroundImage: 'linear-gradient(rgb(0 0 0/80%) 0 0)',
  },
  clipLabelContainer: {
    display: 'flex',
    gap: '0.4rem',
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    webkitUserSelect: 'none!important',
    userSelect: 'none!important',
  },
  clipLabel: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50ch',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '30ch',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '70ch',
  },
  deleteButton: {
    color: '#fff!important',
    minWidth: '0',
    "&.Mui-disabled": {
      color: '#818181!important'
    }
  },
}));

export { useStyles };
