import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useStyles } from './VideoEditorPage.styles';

import { revideoUrl } from '../../constants/app.constants';

import { Player } from'@revideo/player-react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { checkValidLoginStatus } from '../../utils/user.utils';

import VideoEditorTimeline from '../layout/VideoEditorTimeline';

import AddSourceModal from '../layout/AddSourceModal';
import VideoExportModal from '../layout/VideoExportModal';
import TextEditorModal from '../layout/TextEditorModal';

import PlaySvg from '../../img/play.svg';
import PauseSvg from '../../img/pause.svg';
import MagnetSvg from '../../img/magnet.svg';

import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Add,
  Remove,
  TextFields,
  Edit,
  GetApp,
  UnfoldLess,
  Visibility,
  VisibilityOff,
  VolumeUp,
  VolumeOff,
} from '@material-ui/icons';

function VideoEditorPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  // modal props
  const [addSourceModalProps, setAddSourceModalProps] = useState({});
  const [textEditorModalProps, setTextEditorModalProps] = useState({});
  const [videoExportModalProps, setVideoExportModalProps] = useState({});
  
  // timeline
  const [tlData, setTLData] = useState([]);
  const tlState = useRef();

  // timeline scale
  const [scale, setScale] = useState(10);
  const [scaleWidth, setScaleWidth] = useState(100);
  const [scaleCount, setScaleCount] = useState(6);
  const [zoom, setZoom] = useState(1);

  // timeline controls
  const [playing, setPlaying] = useState(false);
  const [activeAction, setActiveAction] = useState();
  const [snapMode, setSnapMode] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [scrollLocked, setScrollLocked] = useState(false);

  // revideo data
  const [variables, setVariables] = useState({
    videos: [],
    audio: [],
    images: [],
    texts: [],
    watermark: true,
  });

  const projectEmpty = variables.videos.length == 0 &&
    variables.audio.length == 0 &&
    variables.images.length == 0 &&
    variables.texts.length == 0;

  // set project settings
  const handleExport = async () => {
    setVideoExportModalProps({
      open: true,
      variables: variables,
      onVideoExportModalClose: async (showWatermark) => {
        setVariables(prevState => ({
          ...prevState,
          watermark: showWatermark,
        }));
        setVideoExportModalProps({ open: false });
      },
    })
  };

  // add source
  const handleAddSource = async () => {
    setAddSourceModalProps({
      open: true,
      onAddSource: async (url, name, duration, type) => {
        const id = Math.floor(Math.random() * 1000);
        if (type === 'video') {
          const newVideo = {
            id,
            url,
            start: 0,
            duration: duration > 60 ? 60 : duration,
            offset: 0,
            position: [0, 100],
            scale: 1,
            visible: true,
            muted: false,
          };
          setVariables((prevState) => ({
            ...prevState,
            videos: [...prevState.videos, newVideo]
          }));
          addClipToTrack(id, name, duration, type, 1);
        }
        else if (type === 'audio') {
          const newAudio = {
            id,
            url,
            start: 0,
            duration: duration > 60 ? 60 : duration,
            offset: 0,
            volume: 1,
            muted: false,
          };
          setVariables((prevState) => ({
            ...prevState,
            audio: [...prevState.audio, newAudio]
          }));
          addClipToTrack(id, name, duration, type);
        }
        else if (type === 'image') {
          const newImage = {
            id,
            url,
            start: 0,
            duration: duration,
            offset: 0,
            position: [0, 100],
            scale: 1,
            visible: true,
          };
          setVariables((prevState) => ({
            ...prevState,
            images: [...prevState.images, newImage]
          }));
          addClipToTrack(id, name, duration, type, 1);
        }
        setAddSourceModalProps({ open: false });
      },
      onAddSourceModalClose: () => {
        setAddSourceModalProps({ open: false });
      },
    });
  };

  // text styling
  const handleAddText = async () => {
    setTextEditorModalProps({
      open: true,
      mode: 'add',
      onAdd: async (text, color, weight) => {
        const id = Math.floor(Math.random() * 1000);
        const newText = {
          id,
          text,
          color,
          weight,
          start: 0,
          duration: 60,
          offset: 0,
          position: [0, -700],
          scale: 1,
          visible: true,
        };
        setVariables((prevState) => ({
          ...prevState,
          texts: [...prevState.texts, newText]
        }));
        addClipToTrack(id, text, 60, 'text', 1);
        setTextEditorModalProps({ open: false });
      },
      onTextEditorModalClose: () => {
        setTextEditorModalProps({ open: false });
      },
    });
  };

  // text editing
  const handleEditText = (action) => {
    const textSource = variables.texts.find((text) => text.id === action.id);
    setTextEditorModalProps({
      open: true,
      mode: 'edit',
      textSource: textSource,
      onEdit: async (text, color, weight) => {
        const updatedText = {
          ...textSource,
          text,
          color,
          weight,
        };
        setVariables((prevState) => ({
          ...prevState,
          texts: prevState.texts.map((item) =>
            item.id === textSource.id ? updatedText : item
          ),
        }));
        action.name = text;
        setTextEditorModalProps({ open: false });
      },
      onTextEditorModalClose: () => {
        setTextEditorModalProps({ open: false });
      },
    });
  };

  const addClipToTrack = (id, name, duration, type, scale = null) => {
    const track = {
      id: id,
      actions: [],
      type,
    };
    const action = {
      id: id,
      start: 0,
      end: duration > 60 ? 60 : duration,
      maxDuration: duration,
      offset: 0,
      trimStart: 0,
      maxEnd: 60,
      effectId: type,
      name,
      scale,
      visible: true,
      muted: false,
    }
    track.actions.push(action);
    setTLData(prevTlData => {
      return [track, ...prevTlData]
    });
  }

  const toggleVisibility = (action) => {
    setVariables(prevState => {
      const updateClipVisibility = (mediaArray) => {
        return mediaArray.map(clip => {
          if (action.id === clip.id) {
            action.visible = !clip.visible;
            return { ...clip, visible: !clip.visible };
          }
          return clip;
        });
      };
      if (action.effectId === 'video') {
        return { ...prevState, videos: updateClipVisibility(prevState.videos) };
      } else if (action.effectId === 'image') {
        return { ...prevState, images: updateClipVisibility(prevState.images) };
      } else if (action.effectId === 'text') {
        return { ...prevState, texts: updateClipVisibility(prevState.texts) };
      }
      return prevState;
    });
  };

  const toggleMute = (action) => {
    setVariables(prevState => {
      const updateClipMute = (mediaArray) => {
        return mediaArray.map(clip => {
          if (action.id === clip.id) {
            action.muted = !clip.muted;
            return { ...clip, muted: !clip.muted };
          }
          return clip;
        });
      };
      if (action.effectId === 'video') {
        return { ...prevState, videos: updateClipMute(prevState.videos) };
      } else if (action.effectId === 'audio') {
        return { ...prevState, audio: updateClipMute(prevState.audio) };
      }
      return prevState;
    });
  };

  const changePosition = (action, axis, increment) => {
    setVariables(prevState => {
      const updateClipPosition = (mediaArray) => {
        return mediaArray.map(clip => {
          if (action.id === clip.id) {
            const newPosition = [...clip.position];
            if (axis === 'x') {
              newPosition[0] += increment;
            } else if (axis === 'y') {
              newPosition[1] += increment;
            }
            return { ...clip, position: newPosition };
          }
          return clip;
        });
      };
      if (action.effectId === 'video') {
        return { ...prevState, videos: updateClipPosition(prevState.videos) };
      } else if (action.effectId === 'image') {
        return { ...prevState, images: updateClipPosition(prevState.images) };
      } else if (action.effectId === 'text' ) {
        return { ...prevState, texts: updateClipPosition(prevState.texts) };
      }
      return prevState;
    });
  };
  
  const changeScale = (action, increment) => {
    setVariables(prevState => {
      const updateClipScale = (mediaArray) => {
        return mediaArray.map(clip => {
          if (action.id === clip.id) {
            let newScale = clip.scale + increment;
            newScale = Math.max(0.1, Math.min(newScale, 4));
            return { ...clip, scale: newScale };
          }
          return clip;
        });
      };
      if (action.effectId === 'video') {
        return { ...prevState, videos: updateClipScale(prevState.videos) };
      } else if (action.effectId === 'image') {
        return { ...prevState, images: updateClipScale(prevState.images) };
      } else if (action.effectId === 'text' ) {
        return { ...prevState, texts: updateClipScale(prevState.texts) };
      }
      return prevState;
    });
  };

  const resetScale = (action) => {
    setVariables(prevState => {
      const updateClipScale = (mediaArray) => {
        return mediaArray.map(clip => {
          if (action.id === clip.id) {
            return { ...clip, scale: 1 };
          }
          return clip;
        });
      };
      if (action.effectId === 'video') {
        return { ...prevState, videos: updateClipScale(prevState.videos) };
      } else if (action.effectId === 'image') {
        return { ...prevState, images: updateClipScale(prevState.images) };
      } else if (action.effectId === 'text' ) {
        return { ...prevState, texts: updateClipScale(prevState.texts) };
      }
      return prevState;
    });
  };

  const getScale = (id, type) => {
    if (type === 'video') {
      const video = variables.videos.find((video) => video.id === id);
      return video ? `${Math.round(video.scale * 100)}%` : null;
    } else if (type === 'image') {
      const image = variables.images.find((image) => image.id === id);
      return image ? `${Math.round(image.scale * 100)}%` : null;
    } else if (type === 'text') {
      const text = variables.texts.find((text) => text.id === id);
      return text ? `${Math.round(text.scale * 100)}%` : null;
    }
    return null;
  };

  // format timestamp
  const formatTime = (currentTime) => {
    const minutes = Math.floor(currentTime / 60);
    const seconds = Math.floor(currentTime % 60);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  // prevent play when text editor is open
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (!textEditorModalProps.open) {
        if (event.code === "Space") {
          event.preventDefault();
          setPlaying(!playing);
        }
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [playing, textEditorModalProps]);

  // disable scroll when clip is selected to allow trimming on mobile
  useEffect(() => {
    const preventScroll = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    if (scrollLocked) {
      window.addEventListener('wheel', preventScroll, { passive: false });
      window.addEventListener('touchmove', preventScroll, { passive: false });
    }
    return () => {
      window.removeEventListener('wheel', preventScroll);
      window.removeEventListener('touchmove', preventScroll);
    };
  }, [scrollLocked]);

  // make timeline width responsive and control zoom
  useEffect(() => {
    const updateTimelineParams = () => {
      const containerWidth = document.querySelector('.timeline-editor').offsetWidth - 24;
      let newScale = zoom === 1 ? 10 : zoom === 2 ? 5 : 1;
      if (window.innerWidth < 720) newScale *= 2;
      setScale(newScale);

      const newScaleCount = 60 / newScale;
      const scaleFactor = zoom === 1 ? 1 : zoom === 2 ? 2 : 10;
      const newScaleWidth = ((containerWidth / newScaleCount) * scaleFactor);
      
      setScaleWidth(newScaleWidth);
      setScaleCount(newScaleCount);
    };
    updateTimelineParams();
    window.addEventListener('resize', updateTimelineParams);

    return () => window.removeEventListener('resize', updateTimelineParams);
  }, [zoom]);
  
  return (
    <>
      <AddSourceModal {...addSourceModalProps} />
      <TextEditorModal {...textEditorModalProps} />
      <VideoExportModal {...videoExportModalProps} />

      <Grid container className={classes.canvasContainer}>
        <Grid container item justifyContent='center'>
          <Button
            className={classes.verticalButton}
            disabled={activeAction == null || activeAction?.effectId === 'audio'}
            onClick={() => changePosition(activeAction, 'y', -50)}
          >
            <KeyboardArrowUp fontSize='small' />
          </Button>
        </Grid>
        <Button
          className={classes.horizontalButton}
          disabled={activeAction == null || activeAction?.effectId === 'audio'}
          onClick={() => changePosition(activeAction, 'x', -50)}
        >
          <KeyboardArrowLeft fontSize='small' />
        </Button>
        <div className={`${classes.canvas} ${classes.verticalCanvas}`}>
          <Player
            // player connects to revideo
            src={revideoUrl}
            controls={false}
            playing={playing}
            looping={false}
            width={1080}
            height={1920}
            quality={0.5}
            variables={variables}
            currentTime={currentTime}
            onTimeUpdate={async (time) => {
              setCurrentTime(time)
              tlState.current.setTime(time)
            }}
            style={{ pointerEvents: 'none!important' }}
          />
          <div
            // invisble div to disable clicks on the player
            // cannot disable player clicks on current revideo version
            className={classes.disableClick}
            onClick={(e) => e.preventDefault()}
          />
          <Button
            disabled={projectEmpty}
            className={classes.exportButton}
            onClick={() => handleExport()}
          >
            <GetApp fontSize='inherit' />
          </Button>
        </div>
        <Button
          className={classes.horizontalButton}
          disabled={activeAction == null || activeAction?.effectId === 'audio'}
          onClick={() => changePosition(activeAction, 'x', 50)}
        >
          <KeyboardArrowRight fontSize='small' />
        </Button>
        <Grid container item justifyContent='center'>
          <Button
            className={classes.verticalButton}
            disabled={activeAction == null || activeAction?.effectId === 'audio'}
            onClick={() => changePosition(activeAction, 'y', 50)}
          >
            <KeyboardArrowDown fontSize='small' />
          </Button>
        </Grid>
        <Grid className={classes.clipControlContainer}>
          <Button
            // toggle clip visibility
            className={classes.controlButton}
            disabled={activeAction == null || activeAction?.effectId === 'audio'}
            onClick={() => {
              toggleVisibility(activeAction);
            }}
          >
            {
              !activeAction ? <Visibility fontSize='small' />
              : activeAction?.visible
              ? <Visibility fontSize='small' />
              : <VisibilityOff fontSize='small' />
            }
          </Button>
          <Button
            // toggle audio muting
            className={classes.controlButton}
            disabled={activeAction == null || activeAction?.effectId === 'image' || activeAction?.effectId === 'text'}
            onClick={() => {
              toggleMute(activeAction);
            }}
          >
            {
              !activeAction ? <VolumeUp fontSize='small' />
              : activeAction?.muted
              ? <VolumeOff fontSize='small' />
              : <VolumeUp fontSize='small' />
            }
          </Button>
          <div className={`${classes.scaleInputContainer} ${(activeAction == null || activeAction?.scale == null) && classes.disabledInput}`}>
            <Button
              // change scale
              className={classes.scaleButton}
              disabled={activeAction == null || activeAction?.effectId === 'audio'}
              onClick={() => {
                changeScale(activeAction, -0.1);
              }}
            >
              <Remove fontSize='inherit'/>
            </Button>
            <div className={classes.scaleInput}>
              {activeAction && activeAction.effectId !== 'audio' ? getScale(activeAction.id, activeAction.effectId) : '100%'}
            </div>
            <Button
              className={classes.scaleButton}
              disabled={activeAction == null || activeAction?.effectId === 'audio'}
              onClick={() => {
                  changeScale(activeAction, 0.1);
              }}
            >
              <Add fontSize='inherit'/>
            </Button>
          </div>
          <Button
            // reset scale
            className={classes.controlButton}
            disabled={activeAction == null || activeAction?.effectId === 'audio'}
            onClick={() => {
              resetScale(activeAction);
            }}
          >
            <UnfoldLess fontSize='small' style={{ transform: 'rotate(45deg)' }}/>
          </Button>
        </Grid>
      </Grid>
      
      <Grid container spacing={3} className={classes.timelineOuterContainer}>
        <Grid container item xs={12} sm={12} md={12} className={classes.timelineBar}>
          <Grid container item xs={3} sm={4} className={classes.timelineButtonContainer}>
            <Button
              // add source
              className={classes.addSourceButton}
              onClick={() => {
                if (checkValidLoginStatus(user.id, dispatch)) {
                  handleAddSource();
                }
              }}
            >
              <Add className={classes.buttonIcon} fontSize='small' />
              <div className={classes.buttonText}>
                + {t('videoEditorTab.addSource')}
              </div>
            </Button>
            <Button
              // add text
              className={classes.addTextButton}
              onClick={() => {
                if (checkValidLoginStatus(user.id, dispatch)) {
                  if (activeAction && activeAction?.effectId === 'text') {
                    handleEditText(activeAction);
                  } else {
                    handleAddText();
                  }
                }
              }}
            >
              {
                activeAction && activeAction?.effectId === 'text'
                ? <Edit fontSize='small'/>
                : <TextFields fontSize='small'/>
              }
            </Button>
          </Grid>
          <Grid
            className={classes.timelineControls}
            container
            item
            xs
          >
            <div className={classes.timelineButtonContainer}>
              <Button
                // play button
                size='small'
                disableRipple
                className={classes.timelineButton}
                onClick={() => setPlaying(!playing)}
              >
                <img src={!playing ? PlaySvg : PauseSvg} className={classes.iconButton}/>
              </Button>
              <div className={classes.timestamp}>
                {formatTime(currentTime)} / 01:00
              </div>
              <Button
                // enable clip snapping (dragging or trimming snaps to adjacent clips and playhead)
                className={`${classes.toggleButton} ${snapMode && classes.activeToggleButton}`}
                onClick={() => setSnapMode(!snapMode)}
              >
                <img
                  src={MagnetSvg}
                  style={{ filter: snapMode && 'brightness(0)' }}
                />
              </Button>
            </div>
          </Grid>
          <Grid container item xs={3} sm={4} className={classes.timelineButtonContainer} justifyContent='flex-end'>
            <div className={classes.zoomInputContainer}>
              <Button
                // adjust timeline zoom
                className={classes.zoomButton}
                disabled={zoom === 1}
                disableRipple
                onClick={() => {
                  setZoom(zoom / 2)
                }}
              >
                <Remove fontSize='inherit'/>
              </Button>
              <div className={classes.zoomInput}>
                {zoom}x
              </div>
              <Button
                className={classes.zoomButton}
                disabled={zoom === 4}
                disableRipple
                onClick={() => {
                  setZoom(zoom * 2)
                }}
              >
                <Add fontSize='inherit'/>
              </Button>
            </div>
          </Grid>
        </Grid>

        <div>
          <VideoEditorTimeline
            classes={classes}
            timelineData={tlData}
            timelineState={tlState}
            snapMode={snapMode}
            scale={scale}
            scaleWidth={scaleWidth}
            minScaleCount={scaleCount}
            maxScaleCount={scaleCount}
            onDragging={() => setScrollLocked(!scrollLocked)}
            // select clip
            onClickActionOnly={(_, { action }) => {
              if (activeAction !== action) {
                setActiveAction(action);
              } else {
                setActiveAction();
              }
            }}
            getActionRender={action => {
              const clipColor =
                action.effectId === 'video'
                  ? '#C0F0FF'
                  : action.effectId === 'audio'
                  ? '#E1FFB0'
                  : action.effectId === 'image'
                  ? '#FFC0E9'
                  : action.effectId === 'text'
                  ? '#FFCA8F'
                  : '';
              return (
                <div
                  style={{ '--clip-color': clipColor }}
                  className={
                    `${classes.timelineClip} ${action.id === activeAction?.id && classes.activeClip}`
                  }
                >
                  <div className={classes.clipLabelContainer}>
                    <div className={classes.clipLabel}>{action.name}</div>
                    {!action.visible && <VisibilityOff fontSize='small'/>}
                    {action.muted && <VolumeOff fontSize='small'/>}
                  </div>
                </div>
              )
            }}
            onPreviewTime={time => {
              setCurrentTime(time);
            }}
            // move clip
            onOffsetChange={(action) => {
              if (!action || !action.id) return;
              const moveClip = (array, action) => {
                return array.map(clip => {
                  if (clip.id === action.id) {
                    action.offset = action.start; 
                    return { 
                      ...clip, 
                      offset: action.start,
                    };
                  }
                  return clip;
                });
              };
              setVariables((prevState) => {
                if (action.effectId === 'video') {
                  return { ...prevState, videos: moveClip(prevState.videos, action) };
                } else if (action.effectId === 'audio') {
                  return { ...prevState, audio: moveClip(prevState.audio, action) };
                } else if (action.effectId === 'image') {
                  return { ...prevState, images: moveClip(prevState.images, action) };
                } else if (action.effectId === 'text') {
                  return { ...prevState, texts: moveClip(prevState.texts, action) };
                }
                return prevState;
              });
              setScrollLocked(false);
            }}
            // trim clip
            onDurationChange={({ dir, action }) => {
              if (!action || !action.id) return;
              const newDuration = action.end - action.start;
              const resizeClip = (array, action) => {
                return array.map(clip => {
                  if (clip.id === action.id) {
                    if (dir === 'left') {
                      const newStart = clip.start + (action.start - action.offset)
                      action.offset = action.start;
                      action.trimStart = newStart;
                      console.log(newStart);
                      return { 
                        ...clip, 
                        offset: action.start,
                        duration: newDuration,
                        start: newStart,
                      };
                    } else if (dir === 'right') {
                      return { ...clip, duration: newDuration };
                    }
                  }
                  return clip;
                });
              };
              setVariables((prevState) => {
                if (action.effectId === 'video') {
                  return { ...prevState, videos: resizeClip(prevState.videos, action) };
                } else if (action.effectId === 'audio') {
                  return { ...prevState, audio: resizeClip(prevState.audio, action) };
                } else if (action.effectId === 'image') {
                  return { ...prevState, images: resizeClip(prevState.images, action) };
                } else if (action.effectId === 'text') {
                  return { ...prevState, texts: resizeClip(prevState.texts, action) };
                }
                return prevState;
              });
              setScrollLocked(false);
            }}
            // delete clip
            onDelete={(track) => {
              setVariables((prevState) => {
                if (track.type === 'video') {
                  return { ...prevState, videos: prevState.videos.filter(clip => clip.id !== track.id) };
                } else if (track.type === 'audio') {
                  return { ...prevState, audio: prevState.audio.filter(clip => clip.id !== track.id) };
                } else if (track.type === 'image') {
                  return { ...prevState, images: prevState.images.filter(clip => clip.id !== track.id) };
                } else if (track.type === 'text') {
                  return { ...prevState, texts: prevState.texts.filter(clip => clip.id !== track.id) };
                }
                return prevState;
              });
              setTLData((prevTLData) => {
                return prevTLData.filter(clip => track.id !== clip.id);
              });
              setActiveAction();
            }}
          />
        </div>
      </Grid>
    </>
  );
}

export default VideoEditorPage;
