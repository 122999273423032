import Modal from '@material-ui/core/Modal';
import { useStyles } from './VideoExportModal.styles';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { appActions } from '../../actions/app.actions.js';

import { startVideoRenderJob } from '../../services/page.services';

import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Download from '@mui/icons-material/Download';

import HtmlTooltip from '../HtmlTooltip';
import tooltipImg from '../../img/tooltip.png';

export default function VideoSettingsModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const { open, variables } = props;
  const user = useSelector(state => state.user);
  const tierToShowOutputFormat = ['PREMIUM', 'PRO', 'ADMIN', 'CUSTOM'];

  const [exportName, setExportName] = useState('');
  const [showWatermark, setShowWatermark] = useState(true);

  const [rendering, setRendering] = useState(false);
  const [result, setResult] = useState();

  const [isDisabled, setIsDisabled] = useState(true);
  
  const [fontStyle, setFontStyle] = useState('TheJamsil');

  useEffect(() => {
    const defaultLanguage = localStorage.getItem('selectedLanguage') || navigator.language.split('-')[0];
    const defaultFont = defaultLanguage === 'ja' ? 'MPlus1' : 'TheJamsil';
    setFontStyle(defaultFont);
  }, []);

  const handleStart = async () => {
    if (exportName.length > 0) {
      try {
        setRendering(true);
        const renderVariables = {
          ...variables,
          userId: user.id,
          exportName: exportName,
          showWatermark: showWatermark,
        };
        const result = await startVideoRenderJob(renderVariables);
        setResult(result);
        handleDownload(result);
      } catch (e) {
        console.log('error during video render job: ', e);
        dispatch(appActions.openMessageModal(t('videoExportModal.videoRenderError'), true));
      } finally {
        setRendering(false);
      }
    }
  };

  const handleDownload = (url) => {
    try {
      const tempLink = document.createElement('a');
      tempLink.href = url;
      if (tempLink.href) {
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      }
    } catch (e) {
      setLoading(false);
      console.log('e : ', e);
    }
  };

  useEffect(() => {
    if (!tierToShowOutputFormat.includes(user.subscription?.type)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [user.subscription?.type]);

  const classes = useStyles();
  return (
    <>
      <Modal
        open={!!open}
        onClose={() => {
          if (rendering) return;
          props.onVideoExportModalClose(showWatermark)
        }}
        className={`${classes.modal} ${fontStyle}`}
      >
        <div className={classes.container}>
          {!result && (
            <>
              <div className={classes.titleContainer}>
                <div className={classes.pageTitle}>
                  {t('videoExportModal.title')}
                </div>
                <div className={classes.startButtonContainer}>
                  <HtmlTooltip
                    title={
                      <div className={classes.tooltipContainer}>
                        <div className={classes.tooltipTextContainer}>
                          <div className={`${classes.tooltipText} ${classes.tooltipTextBullet}`}>&#x2022;</div>
                          <div className={classes.tooltipText}>{t('videoExportModal.tooltip.contents.0')}</div>
                        </div>
                      </div>
                    }
                  >
                    <img className={classes.tooltipImg} src={tooltipImg} alt="tooltip-img" />
                  </HtmlTooltip>
                  <Button
                    className={classes.startButton}
                    disabled={exportName.length <= 0 || rendering }
                    onClick={handleStart}
                    disableRipple
                  >
                    {t('videoExportModal.start')}
                  </Button>
                </div>
              </div>
              <div className={classes.separater} />
              {rendering && (
                <div className={classes.progressContainer}>
                  <div className={classes.renderingText}>
                    <div>{t('videoExportModal.rendering.0')}</div>
                    <div>{t('videoExportModal.rendering.1')}</div>
                  </div>
                  <CircularProgress size={32} color='inherit' />
                </div>
              )}
              {!rendering && (
                <>
                  <input
                    className={classes.labelInput}
                    placeholder={t('videoExportModal.placeholder')}
                    value={exportName}
                    onChange={label => setExportName(label.target.value)}
                  />
                  <div className={classes.optionContainer}>
                    <div className={isDisabled && classes.disabledCheckbox}>
                      {t('videoExportModal.watermark')}
                      <Checkbox
                        className={classes.checkbox}
                        disabled={isDisabled}
                        checked={showWatermark}
                        onChange={() => setShowWatermark(!showWatermark)}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {result && (
            <>
              <div className={classes.pageTitle}>{t('videoExportModal.success.0')}</div>
              <div className={classes.separater} />
              <div className={classes.success}>{t('videoExportModal.success.1')}</div>
              <div className={classes.downloadContainer}>
                {`${exportName}.mp4`}
                <Button
                  className={classes.downloadButton}
                  onClick={() => handleDownload(result)}
                >
                  <Download fontSize='small'/>
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
